import { Box, Grid, GridItem, Heading, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import PersonCard from './PersonCard';
import Delayed from './Delayed';

const CalendarByUser = () => {

    const [people, setPeople] = useState([]);
    const [total, setTotal] = useState();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/get_people' : '/api/get_people')
            .then((res) => res.json())
            .then((data) => {
                setPeople(data.rows.result);
                setTotal(data.rows.total);
                setLoading(false);
            })
    }, []);

    return (
        <>
            {
                loading
                    ? (
                        <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center'>
                            <Spinner size='xl' thickness='6px' color='red.500' />
                        </Box>
                    )
                    : (
                        <>
                            <Heading my={3}>Total General: {total}</Heading>
                            <Grid flex={1} width='100%' templateColumns='repeat(3, 1fr)' gap={3}>
                                <GridItem>
                                    <PersonCard extraClasses='shiny gold' imageClass='coin-1st' person={people[0]} />
                                </GridItem>
                                <GridItem>
                                    <Delayed waitBeforeShow={100}>
                                        <PersonCard extraClasses='shiny silver' imageClass='coin-2nd' person={people[1]} />
                                    </Delayed>
                                </GridItem>
                                <GridItem>
                                    <Delayed waitBeforeShow={200}>
                                        <PersonCard extraClasses='shiny bronze' imageClass='coin-3rd' person={people[2]} />
                                    </Delayed>
                                </GridItem>
                                <GridItem>
                                    <Delayed waitBeforeShow={300}>
                                        <PersonCard imageClass='happy-face' person={people[3]} />
                                    </Delayed>
                                </GridItem>
                                <GridItem>
                                    <Delayed waitBeforeShow={400}>
                                        <PersonCard imageClass='medium-face' person={people[4]} />
                                    </Delayed>
                                </GridItem>
                                <GridItem>
                                    <Delayed waitBeforeShow={500}>
                                        <PersonCard imageClass='serious-face' person={people[5]} />
                                    </Delayed>
                                </GridItem>
                            </Grid>
                        </>
                    )
            }
        </>
    )
}

export default CalendarByUser