import React, { useEffect, useState } from 'react'
import RexLayout from './Layouts/RexLayout'
import { Badge, Box, Button, Flex, Grid, Heading, HStack, Select, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import * as $ from 'jquery';
import { useLocation } from 'react-router-dom';

const GuidesPage = ({
    setCurrentPage
}) => {

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    const [ruta, setRuta] = useState('0');
    const [column, setColumn] = useState({
        left: [],
        middle: [],
        right: []
    })

    const scrollRecollections = () => {
        let marquee = $('#marquee');

        setTimeout(() => {
            marquee.animate({
                scrollTop: marquee.prop('scrollHeight')
            }, {
                duration: 120000,
                specialEasing: {
                    height: "linear"
                },
                complete: function () {
                    setCurrentPage(3);
                }
            });
        }, 3000);
    }  

    const getGuides = (ruta = '0') => {
        setLoading(true);
        Promise.all([
            fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_guides?row=0&tipo=${ruta}` : `/api/get_guides?row=0&tipo=${ruta}`),
            fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_guides?row=1&tipo=${ruta}` : `/api/get_guides?row=1&tipo=${ruta}`),
            fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_guides?row=2&tipo=${ruta}` : `/api/get_guides?row=2&tipo=${ruta}`)
        ]).then(async ([leftCol, middleCol, rightCol]) => {
            leftCol = await leftCol.json();
            middleCol = await middleCol.json();
            rightCol = await rightCol.json();

            setColumn({
                left: leftCol.data,
                middle: middleCol.data,
                right: rightCol.data,
            });
            setLoading(false);
        });
    }

    const handleChangeRuta = async (event) => {
        setRuta(event.target.value);
        getGuides(event.target.value);
    }

    useEffect(() => {
        getGuides();
    }, []);

    useEffect(() => {
        if (!loading && !pathname.includes('reportes')) {
            scrollRecollections();
        }
    }, [loading]);
    


    return (
        <>
            <Flex justifyContent='space-between' w='100%'>
                <Heading my={2}>Pendientes de entrega</Heading>
                {
                    (pathname.includes('reportes')) && (
                        <HStack>
                            <Box>
                                <HStack>
                                    <Text fontWeight='bold'>Ruta</Text>
                                    <Select onChange={handleChangeRuta}>
                                        <option value="2" selected={(ruta === '2' && 'selected')}>Todo</option>
                                        <option value="1" selected={(ruta === '1' && 'selected')}>Ruta directa</option>
                                        <option value="0" selected={(ruta === '0' && 'selected')}>Reparto local</option>
                                    </Select>
                                </HStack>
                            </Box>
                            <Box>
                                <Button onClick={() => getGuides(ruta)} leftIcon={<i className='bi bi-arrow-clockwise'></i>}>Actualizar</Button>
                            </Box>
                        </HStack>
                    )
                }
            </Flex>
            {
                loading 
                ? (
                    <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Spinner size='xl' thickness='6px' color='red.500' />
                        <Text>Cargando</Text>
                    </Box>
                )
                : (
                    <Flex w='100%' gap={3}>
                        <Box display='flex' flexDirection='column' flex={1} border='1px solid gray' p='5px' borderRadius={5} height='calc(100vh - 155px)'>
                            <HStack justifyContent='space-between' mb={1}>
                                <Heading fontSize='lg' textAlign='center'>SERVICIOS ATRASADOS +72HRS</Heading>
                                <Badge bgColor='red.500' color='white' fontSize='lg'>TOTAL: {column.left.length}</Badge>
                            </HStack>
                            <Table fontSize='xs' variant='striped'>
                                <Thead>
                                    <Tr>
                                        <Th w='18%' p={0}>GUÍA</Th>
                                        <Th p={0}>NOMBRE</Th>
                                        <Th w='15%' p={0}>DÍAS PENDIENTES</Th>
                                    </Tr>
                                </Thead>
                            </Table>
                            <Box id='marquee' flex={1} overflow='scroll'>
                                <Table fontSize='xs' variant='striped' h='100%' colorScheme='red'>
                                    <Tbody>
                                        {
                                            column.left.map((guide) => (
                                                <Tr>
                                                    <Td fontWeight='semibold' w='18%' p={0}>{guide.TRACKID}</Td>
                                                    <Td p={1}>{guide.NAME}</Td>
                                                    <Td w='15%' p={0}>{guide.DIASPENDIENTES}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='column' flex={1} border='1px solid gray' p='5px' borderRadius={5} height='calc(100vh - 155px)'>
                            <HStack justifyContent='space-between' mb={1}>
                                <Heading fontSize='lg' textAlign='center'>SERVICIOS ATRASADOS +24HRS</Heading>
                                <Badge bgColor='orange' color='white' fontSize='lg'>TOTAL: {column.middle.length}</Badge>
                            </HStack>
                            <Table fontSize='xs' variant='striped'>
                                <Thead>
                                    <Tr>
                                        <Th w='18%' p={0}>GUÍA</Th>
                                        <Th p={0}>NOMBRE</Th>
                                        <Th w='15%' p={0}>DÍAS PENDIENTES</Th>
                                    </Tr>
                                </Thead>
                            </Table>
                            <Box flex={1} overflow='scroll'>
                                <Table fontSize='xs' variant='striped' h='100%' colorScheme='orange'>
                                    <Tbody>
                                        {
                                            column.middle.map((guide) => (
                                                <Tr>
                                                    <Td fontWeight='semibold' w='18%' p={0}>{guide.TRACKID}</Td>
                                                    <Td p={1}>{guide.NAME}</Td>
                                                    <Td w='15%' p={0}>{guide.DIASPENDIENTES}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                        <Box display='flex' flexDirection='column' flex={1} border='1px solid gray' p='5px' borderRadius={5} height='calc(100vh - 155px)'>
                            <HStack justifyContent='space-between' mb={1}>
                                <Heading fontSize='lg' textAlign='center'>SERVICIOS EN TIEMPO</Heading>
                                <Badge bgColor='blue' color='white' fontSize='lg'>TOTAL: {column.right.length}</Badge>
                            </HStack>
                            <Table fontSize='xs' variant='striped'>
                                <Thead>
                                    <Tr>
                                        <Th w='18%' p={0}>GUÍA</Th>
                                        <Th p={0}>NOMBRE</Th>
                                        <Th w='15%' p={0}>DÍAS PENDIENTES</Th>
                                    </Tr>
                                </Thead>
                            </Table>
                            <Box flex={1} overflow='scroll'>
                                <Table fontSize='xs' variant='striped' h='100%' colorScheme='blue'>
                                    <Tbody>
                                        {
                                            column.right.map((guide) => (
                                                <Tr>
                                                    <Td fontWeight='semibold' w='18%' p={0}>{guide.TRACKID}</Td>
                                                    <Td p={1}>{guide.NAME}</Td>
                                                    <Td w='15%' p={0}>{guide.DIASPENDIENTES}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    </Flex>
                )
            }
        </>
    )
}

export default GuidesPage
