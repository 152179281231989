import { Flex, Image } from '@chakra-ui/react'
import React from 'react'
import LogoRedex from '../../assets/imgs/logo-rex.png';
import { Outlet } from 'react-router-dom';

const RexLayout = ({
    children
}) => {
  return (
    <Flex p={5} flexDirection='column' alignItems='center' w='100vw' h='100vh'>
      <Image my={2} src={LogoRedex} w='300px' />
      <Outlet />
    </Flex>
  )
}

export default RexLayout