import { Box, Grid, GridItem, Heading, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect, useState } from 'react'

const ExecutivesResults = () => {

    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState(null);
    const [headers, setHeaders] = useState(null);
    const [month, setMonth] = useState();
    const [date, setDate] = useState();

    let MXNPeso = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    });

    const throwPig = () => {
        const pig = document.getElementById('pig');
        pig.classList.add("animate__animated");
        pig.classList.add("animate__headShake");

        setTimeout(() => {
            pig.classList.remove("animate__headShake");
            pig.classList.add("animate__hinge");
        }, 1000);

        setTimeout(() => {
            pig.classList.remove("animate__hinge");
        }, 3000);
    }

    useEffect(() => {
        let localDate = moment();
        localDate.locale('es');
        setDate(localDate.format('LL'));
        setMonth(localDate.format('MMMM'));

        Promise.all([
            fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/get_sells' : '/api/get_sells'),
            fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/get_upper_sells' : '/api/get_upper_sells'),
        ]).then(async ([sells, headers]) => {
            const sellsData = await sells.json();
            setTableData(sellsData.data);

            const headersData = await headers.json();
            setHeaders(headersData.data);

            setLoading(false);
        })
    }, [tableData, setTableData, headers, setHeaders]);


    return (
        <>
            <Heading>Venta Suc. Monterrey / Santa Catarina</Heading>
            {
                loading
                    ? <Text>Cargando...</Text>
                    : (
                        <>
                            <Grid width='100vw' templateColumns='repeat(6, 1fr)' gap={3} my={5} px={5}>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='#FE9F43' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{MXNPeso.format(headers[0]['BUDGETDIARIO'])}</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Presupuesto<br/> Diario</Text>
                                                <Text fontSize={30}>
                                                    <i className='bi bi-cash'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='#04CFE7' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{MXNPeso.format(headers[0]['VENTADIARIA'])}</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Venta<br/> Diaria</Text>
                                                <Text fontSize={30}>
                                                    <i className='bi bi-currency-dollar'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='gray.500' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{headers[0]['CDIARIO']}%</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Cumplimiento<br/> Diario</Text>
                                                <Text fontSize={30}>
                                                    <i className='bi bi-percent'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='#27C66E' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{MXNPeso.format(headers[0]['BUDGETMONTH'])}</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Presupuesto<br/> Mensual</Text>
                                                <Text fontSize={30}>
                                                    <i className='bi bi-wallet2'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='#1D4B8B' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{MXNPeso.format(headers[0]['VENTAMENSUAL'])}</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Venta<br/> Mensual</Text>
                                                <Text onClick={throwPig} fontSize={30} id='pig'>
                                                    <i className='bi bi-piggy-bank'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                                <GridItem className='animate__animated animate__backInDown'>
                                    <Box width='100%' backgroundColor='gray.700' color='white' borderRadius={10} padding={5}>
                                        <VStack alignItems='start'>
                                            <Text fontSize='2xl' fontWeight='bold'>{headers[0]['CMENSUAL']}%</Text>
                                            <HStack w='100%' justifyContent='space-between'>
                                                <Text fontSize='lg' fontWeight='bold'>Cumplimiento<br/> Mensual</Text>
                                                <Text fontSize={30}>
                                                    <i className='bi bi-percent'></i>
                                                </Text>
                                            </HStack>
                                        </VStack>
                                    </Box>
                                </GridItem>
                            </Grid>
                            <Table fontSize={24}>
                                <Thead>
                                    <Tr>
                                        <Th fontSize={24} borderWidth={1} borderColor='gray.500' backgroundColor='gray.300'>Fechas</Th>
                                        <Th fontSize={24} borderWidth={1} borderColor='gray.500' backgroundColor='gray.300' textAlign='center' colSpan={3}>HOY - {date}</Th>
                                        <Th fontSize={24} borderWidth={1} borderColor='gray.500' backgroundColor='gray.300' textAlign='center' colSpan={3}>ACUMULADO MENSUAL - {month}</Th>
                                    </Tr>
                                    <Tr backgroundColor='#1D4B8B'>
                                        <Th fontSize={24} color='white'>Ejecutivo</Th>
                                        <Th textAlign='center' fontSize={24} color='white'>PTO Diario</Th>
                                        <Th textAlign='center' fontSize={24} color='white'>Venta Diaria</Th>
                                        <Th fontSize={24} color='white' width='20px' textAlign='center'>%</Th>
                                        <Th textAlign='center' fontSize={24} color='white'>PTO MENSUAL</Th>
                                        <Th textAlign='center' fontSize={24} color='white'>Venta Mensual</Th>
                                        <Th fontSize={24} color='white' width='20px' textAlign='center'>%</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        tableData.map((row, index) => (
                                            <Tr>
                                                <Td>{row['TEAM']}</Td>
                                                <Td textAlign='center'>{MXNPeso.format(row['BUDGETDIARIO'])}</Td>
                                                <Td textAlign='center'>{MXNPeso.format(row['VENTADIARIA'])}</Td>
                                                <Td fontSize={30} textAlign='center' backgroundColor={(row['CDIARIO'] >= 95 ? 'green' : (row['CDIARIO'] >= 80 && row['CDIARIO'] < 95 ? 'yellow.400' : 'red'))} color='white' fontWeight='bold'>{row['CDIARIO']}%</Td>
                                                <Td textAlign='center'>{MXNPeso.format(row['BUDGETMONTH'])}</Td>
                                                <Td textAlign='center'>{MXNPeso.format(row['VENTAMENSUAL'])}</Td>
                                                <Td fontSize={30} textAlign='center' backgroundColor={(row['CMENSUAL'] >= 95 ? 'green' : (row['CMENSUAL'] >= 80 && row['CMENSUAL'] < 95 ? 'yellow.400' : 'red'))} color='white' fontWeight='bold'>{row['CMENSUAL']}%</Td>
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </>
                    )
            }
        </>
    )
}

export default ExecutivesResults