import { Divider, Flex, Image } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import LogoRedex from '../assets/imgs/logo-rex.png';
import moment from 'moment';
import Citas from '../components/Trafico/Citas';
import Recollections from '../components/Trafico/Recollections';
import GuidesPage from './GuidesPage';
import DetailedCalendar from '../components/DetailedCalendar';

const TraficoPage = () => {

  const [currentPage, setCurrentPage] = useState(0);

  return (
    <Flex p={5} flexDirection='column' alignItems='center' w='100vw' h='100vh'>
      <Image my={2} src={LogoRedex} w='300px' />
      <Divider />
      {
        (currentPage === 0)
          ? <Citas setCurrentPage={setCurrentPage} />
          : (currentPage === 1)
           ? <Recollections setCurrentPage={setCurrentPage} />
            : (currentPage === 2) ? <GuidesPage setCurrentPage={setCurrentPage} /> : <DetailedCalendar setCurrentPage={setCurrentPage} />
      }  
    </Flex>
  )
}

export default TraficoPage