import { Box, Heading, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

const DetailedCalendar = ({
  setCurrentPage
}) => {

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/get_table_data' : '/api/get_table_data')
      .then((res) => res.json())
      .then((data) => {
        setTableData(data.data);
        setHeaders(Object.keys(data.data[0]).filter((key) => key !== 'StartY' && key !== 'StartR' && key !== 'startG'));
        setLoading(false);
      })
  }, []);

  useEffect(() => {
    if(setCurrentPage) {
      setTimeout(() => {
        setCurrentPage(0);
      }, 40000);
    }
  }, [setCurrentPage]);

  return (
    <>
      {
        loading
          ? (
            <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center'>
              <Spinner size='xl' thickness='6px' color='red.500' />
            </Box>
          )
          : (
            <>
              <Heading my={3}>Total General: {tableData[tableData.length - 1]['TOTALES']}</Heading>
              <Table
                className='animate__animated animate__zoomInDown'
                size='sm'
              >
                <Thead>
                  <Tr backgroundColor='#1D4B8B'>
                    {
                      headers.map((header) =>
                        <Th
                          width={(header !== 'RUTA' ? '120px' : 'auto')}
                          color='white'
                          p={2}
                          textAlign={(header !== 'RUTA' ? 'center' : 'left')}
                          borderWidth={1}
                          borderColor='#1D4B8B'
                        >
                          {header}
                        </Th>
                      )
                    }
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    tableData.map((row, index) => (
                      <Tr>
                        {
                          headers.map((header) =>
                            <Td
                              borderWidth={1}
                              borderColor='black'
                              width={(header !== 'RUTA' && header !== 'OTRO HORARIO' && header !== 'TOTALES' ? '50px' : 'auto')}
                              padding={1}
                              textAlign={(header === 'RUTA' ? 'left' : 'center')}
                              fontWeight={(header === 'TOTALES' || index === 21 ? 'bold' : 'regular')}
                              backgroundColor={
                                (row[header] > 0 && row[header]  <= 3 && header !== 'TOTALES' && index !== 21) 
                                  ? 'green' 
                                  : (row[header] > 3 && row[header]  <= 5 && header !== 'TOTALES' && index !== 21) 
                                    ? 'yellow' 
                                    : row[header] > 5 && header !== 'TOTALES' && index !== 21 ? 'red' : 'transparent' }
                            >
                              {row[header]}
                            </Td>
                          )
                        }
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </>
          )
      }
    </>
  )
}

export default DetailedCalendar