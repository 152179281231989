import { Box, Heading, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const PersonCard = ({
    extraClasses,
    imageClass,
    person
}) => {
    useEffect(() => {
    }, []);
    return (
        <Box
            borderColor='gray.400'
            borderWidth={1}
            borderRadius={15}
            p={5}
            h='100%'
            className={`animate__animated animate__fadeInDown person-card ${extraClasses}`}
        >
            <HStack justifyContent='space-between'>
                <Heading size='md'>{person.USERNAME}</Heading>
                <div className={`animate__animated animate__flip animate__delay-1s coins ${imageClass}`}></div>
            </HStack>
            <UnorderedList listStyleType='none' m={0}>
                <ListItem bgColor='gray.100' p={2}>
                    <HStack justifyContent='space-between'>
                        <Text><b>TOTAL</b></Text>
                        <Text ml={3}>{person.TOTAL}</Text>
                    </HStack>
                </ListItem>
                <ListItem bgColor='gray.300' p={2}>
                    <HStack justifyContent='space-between'>
                        <Text><b>EN PROCESO</b></Text>
                        <Text ml={3}>{person.INPROCESS}</Text>
                    </HStack>
                </ListItem>
                <ListItem bgColor='gray.100' p={2}>
                    <HStack justifyContent='space-between'>
                        <Text><b>COMPLETADO</b></Text>
                        <Text ml={3}>{person.COMPLETED}</Text>
                    </HStack>
                </ListItem>
                <ListItem bgColor='gray.300' p={2}>
                    <HStack justifyContent='space-between'>
                        <Text><b>CANCELADO POR EL CLIENTE</b></Text>
                        <Text ml={3}>{person.CANCELEDBYCUST}</Text>
                    </HStack>
                </ListItem>
                <ListItem bgColor='gray.100' p={2}>
                    <HStack justifyContent='space-between'>
                        <Text><b>CANCELADO POR LA EMPRESA</b></Text>
                        <Text ml={3}>{person.CANCELBYCOMPANY}</Text>
                    </HStack>
                </ListItem>
            </UnorderedList>
        </Box>
    )
}

export default PersonCard