import { Card, Container, Heading, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const ScreensPage = () => {
  return (
    <Container maxW='full'>
        <Heading>Listado de pantallas</Heading>
        <List display='flex' flexWrap='wrap' flexDirection='row' gap={2}>
            <ListItem as={Link} to='/' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-tv'></i> Pantalla SAC</Text>
                </Card>
            </ListItem>
            <ListItem as={Link} to='/trafico' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-tv'></i> Pantalla Tráfico</Text>
                </Card>
            </ListItem>
        </List>
    </Container>
  )
}

export default ScreensPage