import { Box, Button, Flex, Grid, GridItem, Heading, HStack, Select, Spinner, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import * as $ from 'jquery';
import { useLocation } from 'react-router-dom';

const Recollections = ({
    setCurrentPage
}) => {

    const scrollView = useRef(null);
    const { pathname } = useLocation();
    const [ruta, setRuta] = useState('2');
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [totals, setTotals] = useState({
        finished: 0,
        inprocess: 0,
        pending: 0,
        assigned: 0,
        canceled: 0
    });

    const status_icon = {
        COMPLETADA: 'bi bi-check-circle',
        PENDIENTE: 'bi-clock',
        'EN PROCESO': 'bi-hourglass-split',
        ASIGNADA: 'bi-clipboard-check',
        CANCELADA: 'bi bi-x-circle'
    };

    const status_colors = {
        PENDIENTE: 'gray.500',
        ASIGNADA: 'blue.500',
        'EN PROCESO': '#FF811E',
        CANCELADA: 'red.500',
        COMPLETADA: '#58B25A',
    };

    const getRecollections = (ruta = 2) => {
        setLoading(true);
        setTotals({
            finished: 0,
            inprocess: 0,
            pending: 0,
            assigned: 0,
            canceled: 0
        });
        fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_recollections?tipo=${ruta}` : `/api/get_recollections?tipo=${ruta}`)
            .then((res) => res.json())
            .then((data) => {
                setTableData(data.data);
                setTotals({
                    finished: data.data.filter((item) => item.RECSTATUSDESC === 'COMPLETADA').length,
                    pending: data.data.filter((item) => item.RECSTATUSDESC === 'PENDIENTE').length,
                    inprocess: data.data.filter((item) => item.RECSTATUSDESC === 'EN PROCESO').length,
                    assigned: data.data.filter((item) => item.RECSTATUSDESC === 'ASIGNADA').length,
                    canceled: data.data.filter((item) => item.RECSTATUSDESC === 'CANCELADA').length,
                })
                setLoading(false);
            });
    }

    const handleChangeRuta = async (event) => {
        setRuta(event.target.value);
        getRecollections(event.target.value);
    }

    useEffect(() => {
        getRecollections();
    }, []);

    const scrollRecollections = (loop = 0) => {
        let marquee = $('#marquee');

        marquee.animate({
            scrollTop: marquee.prop('scrollHeight')
        }, {
            duration: 60000,
            specialEasing: {
                height: "linear"
            },
            complete: function () {
                if (loop === 0) {
                    marquee.prop('scrollTop', 0);
                    scrollRecollections(1);
                } else {
                    setCurrentPage(2);
                }
            }
        });
    }

    useEffect(() => {
        if (!loading && !pathname.includes('reportes')) {
            scrollRecollections();
        }
    }, [loading]);




    return (
        <>
            <Flex justifyContent='space-between' w='100%'>
                <Heading my={2}>Calendario de recolecciones</Heading>
                {
                    (pathname.includes('reportes')) && (
                        <HStack>
                            <Box>
                                <HStack>
                                    <Text fontWeight='bold'>Ruta</Text>
                                    <Select onChange={handleChangeRuta}>
                                        <option value="2" selected={(ruta === '2' && 'selected')}>Todo</option>
                                        <option value="1" selected={(ruta === '1' && 'selected')}>Ruta directa</option>
                                        <option value="0" selected={(ruta === '0' && 'selected')}>Reparto local</option>
                                    </Select>
                                </HStack>
                            </Box>
                            <Box>
                                <Button onClick={() => getRecollections(ruta)} leftIcon={<i className='bi bi-arrow-clockwise'></i>}>Actualizar</Button>
                            </Box>
                        </HStack>
                    )
                }
            </Flex>
            {
                !loading
                    ? (
                        <Flex flexDirection='column' justifyContent='space-between' w='100%' gap={2} h='calc(100vh - 145px)'>
                            <HStack justifyContent='space-between'>
                                <Box d='flex' flex={1} flexDirection='column' p={3} borderRadius={5} borderColor='#154D8E' borderWidth={2}>
                                    <Heading fontSize='6xl' color='#154D8E'>{totals.pending}</Heading>
                                    <Heading fontSize='2xl' color='#154D8E'>Recolecciones pendientes</Heading>
                                </Box>
                                <Box d='flex' flex={1} flexDirection='column' p={3} borderRadius={5} borderColor='#154D8E' borderWidth={2}>
                                    <Heading fontSize='6xl' color='#154D8E'>{totals.inprocess}</Heading>
                                    <Heading fontSize='2xl' color='#154D8E'>Recolecciones en proceso</Heading>
                                </Box>
                                <Box d='flex' flex={1} flexDirection='column' p={3} borderRadius={5} backgroundColor='#154D8E'>
                                    <Heading fontSize='6xl' color='white'>{totals.assigned}</Heading>
                                    <Heading fontSize='2xl' color='white'>Recolecciones asignadas</Heading>
                                </Box>
                                <Box d='flex' flex={1} flexDirection='column' p={3} borderRadius={5} bgColor='red.500'>
                                    <Heading fontSize='6xl' color='white'>{totals.canceled}</Heading>
                                    <Heading fontSize='2xl' color='white'>Recolecciones canceladas</Heading>
                                </Box>
                                <Box d='flex' flex={1} flexDirection='column' p={3} borderRadius={5} backgroundColor='#58B25A'>
                                    <Heading fontSize='6xl' color='white'>{totals.finished}</Heading>
                                    <Heading fontSize='2xl' color='white'>Recolecciones completadas</Heading>
                                </Box>
                            </HStack>
                            <Box id='marquee' ref={scrollView} w='100%' overflow='scroll'>
                                <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                                    {
                                        tableData.map((recollection) => (
                                            <GridItem key={recollection.RECOLLECTID} borderWidth={2} borderRadius={5} borderColor='#154D8E' bgColor={(moment(recollection.RECTIME, 'h:m:s').isBefore(moment(), 'h:m:s') && recollection.RECSTATUSDESC === 'PENDIENTE') ? 'red.200' : (recollection.URGENTE === '1' && recollection.RECSTATUSDESC === 'PENDIENTE') ? 'orange.200' : 'transparent'} p={3}>
                                                <Box h='50px' display='flex' alignItems='center'>
                                                    <Text fontSize='xl' fontWeight='bold'>{recollection.CLIENTE}</Text>
                                                </Box>
                                                <Text>{recollection.DIMENSION2_} - {recollection.COUNTYIDTONAME}</Text>
                                                <Text>{recollection.RECOLLECTID}</Text>
                                                <Text><b>Operador:</b> {recollection.DRIVERNAME || 'NO ASIGNADO'}</Text>
                                                <Text><b>Unidad:</b> {recollection.UNITID || 'NO ASIGNADA'}</Text>
                                                <HStack justifyContent='space-between' mb={1}>
                                                    <VStack flex={1} bgColor='gray.300' borderRadius={5} gap={0}>
                                                        <Text lineHeight={1} fontWeight='bold'>Peso</Text>
                                                        <Text fontSize='lg'>{recollection.TOTALWEIGHT}</Text>
                                                    </VStack>
                                                    <VStack flex={1} bgColor='gray.300' borderRadius={5} gap={0}>
                                                        <Text lineHeight={1} fontWeight='bold'>Volumen</Text>
                                                        <Text fontSize='lg'>{recollection.TOTALVOLUME}</Text>
                                                    </VStack>
                                                </HStack>
                                                <Flex flexDirection='row' gap={1} textAlign='center'>
                                                    <Text flex={1} fontSize='md' py={1} px={2} color='white' bgColor={(moment(recollection.RECTIME, 'h:m:s').isBefore(moment(), 'h:m:s') && recollection.RECSTATUSDESC === 'PENDIENTE') ? 'red.500' : 'blue.500'} fontWeight='bold' borderRadius={2}><i className={'bi bi-clock'}></i> {recollection.RECTIME}</Text>
                                                    <Text flex={1} fontSize='md' py={1} px={2} color='white' bgColor={(moment(recollection.RECTIME, 'h:m:s').isBefore(moment(), 'h:m:s') && recollection.RECSTATUSDESC === 'PENDIENTE') ? 'red.500' : status_colors[recollection.RECSTATUSDESC]} fontWeight='bold' borderRadius={2}><i className={status_icon[recollection.RECSTATUSDESC]}></i> {recollection.RECSTATUSDESC}</Text>
                                                </Flex>
                                            </GridItem>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Flex>
                    )
                    : (
                        <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            <Spinner size='xl' thickness='6px' color='red.500' />
                            <Text>Cargando</Text>
                        </Box>
                    )
            }
        </>
    )
}

export default Recollections