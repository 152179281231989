import { Box, Card, Container, Heading, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const HomePage = () => {
  return (
    <Container maxW='full'>
        <Heading>Listado de reportes</Heading>
        <List display='flex' flexWrap='wrap' flexDirection='row' gap={2}>
            <ListItem as={Link} to='/reportes/citas' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-clipboard-data'></i> Reporte de citas</Text>
                </Card>
            </ListItem>
            <ListItem as={Link} to='/reportes/recolecciones' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-clipboard-data'></i> Reporte de recolecciones</Text>
                </Card>
            </ListItem>
            <ListItem as={Link} to='/reportes/calendario-recolecciones' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-clipboard-data'></i> Reporte calendario de recolecciones</Text>
                </Card>
            </ListItem>
            <ListItem as={Link} to='/reportes/guias' target='_blank'>
                <Card p={3} _hover={{bgColor: 'gray.100'}}>
                    <Text fontSize='lg'><i className='bi bi-clipboard-data'></i> Reporte de pendientes de entrega</Text>
                </Card>
            </ListItem>
        </List>
    </Container>
  )
}

export default HomePage