import { Box, Button, Flex, Grid, GridItem, Heading, HStack, Select, Spinner, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

const Citas = ({
    setCurrentPage
}) => {
    const [loading, setLoading] = useState(true);
    const [tipo, setTipo] = useState('0');
    const [tableData, setTableData] = useState([]);
    const [totals, setTotals] = useState({
        finished: 0,
        inprocess: 0,
        unassigned: 0
    });

    const { pathname } = useLocation();

    const status_icon = {
        TERMINADA: 'bi bi-check-circle',
        "EN PROCESO": 'bi-hourglass-split',
        'S/N': 'bi bi-exclamation-circle'
    };

    const status_colors = {
        TERMINADA: '#58B25A',
        "EN PROCESO": '#FF811E',
        "S/N": 'red.500'
    };

    const changeView = () => {
        setTimeout(() => {
            setCurrentPage(1);
        }, 40000);
    }
    
    const getAppointments = (tipo = 0) => {
        setLoading(true);
        console.log(tipo);
        fetch((process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? `http://localhost:8000/api/get_appointments?tipo=${tipo}` : `/api/get_appointments?tipo=${tipo}`)
            .then((res) => res.json())
            .then((data) => {
                setTableData(data.data);
                setTotals({
                    finished: data.data.filter((item) => item.ESTATUSCITA == 'TERMINADA').length,
                    inprocess: data.data.filter((item) => item.ESTATUSCITA == 'EN PROCESO').length,
                    unassigned: data.data.filter((item) => item.ESTATUSCITA == 'S/N').length,
                })

                if(!pathname.includes('reportes')) {
                    changeView();
                }

                setLoading(false);
            });
    }

    const handleChangeTipo = (event) => {
        setTipo(event.target.value);
        getAppointments(event.target.value);
    }

    useEffect(() => {
        getAppointments();
    }, []);

    return (
        <>
            <Flex w='100%' justifyContent='space-between' my={2}>
                <Heading>Calendario de citas</Heading>
                {
                    (pathname.includes('reportes')) && (
                        <HStack>
                            <Box>
                                <HStack>
                                    <Text fontWeight='bold'>Horario</Text>
                                    <Select onChange={handleChangeTipo}>
                                        <option value="0" selected={(tipo === '0' && 'selected')}>Hoy</option>
                                        <option value="1" selected={(tipo === '1' && 'selected')}>Mañana</option>
                                    </Select>
                                </HStack>
                            </Box>
                            <Box>
                                <Button onClick={() => getAppointments(tipo)} leftIcon={<i className='bi bi-arrow-clockwise'></i>}>Actualizar</Button>
                            </Box>
                        </HStack>
                    )
                }
            </Flex>
            {
                !loading
                    ? (
                        <Flex flexDirection='row' justifyContent='space-between' w='100%' gap={2} h='100vh'>
                            <Box flex={3}>
                                <Box d='flex' flexDirection='column' p={3} borderRadius={5} backgroundColor='#58B25A' mb={3}>
                                    <Heading fontSize='6xl' color='white'>{totals.finished}</Heading>
                                    <Heading fontSize='2xl' color='white'>Citas terminadas</Heading>
                                </Box>
                                <Box d='flex' flexDirection='column' p={3} borderRadius={5} backgroundColor='#154D8E' mb={3}>
                                    <Heading fontSize='6xl' color='white'>{totals.unassigned}</Heading>
                                    <Heading fontSize='2xl' color='white'>Citas sin asignar</Heading>
                                </Box>
                                <Box d='flex' flexDirection='column' p={3} borderRadius={5} borderColor='#154D8E' borderWidth={4}>
                                    <Heading fontSize='6xl' color='#154D8E'>{totals.inprocess}</Heading>
                                    <Heading fontSize='2xl' color='#154D8E'>Citas en proceso</Heading>
                                </Box>
                            </Box>
                            <Box flex={9}>
                                <Grid templateColumns='repeat(4, 1fr)' gap={1}>
                                    {
                                        tableData.map((appointment) => (
                                            <GridItem key={appointment.TRACKID}>
                                                <Box borderWidth={2} borderRadius={5} borderColor='#154D8E' p={3}>
                                                    <Box h='50px' display='flex' alignItems='center'>
                                                        <Text fontSize='xl' fontWeight='bold'>{appointment.NAME}</Text>
                                                    </Box>
                                                    <Text>{appointment.OFFICEDIMTO} - {appointment.COUNTYIDTONAME}</Text>
                                                    <Text>{appointment.TRACKID} / {appointment.INVOICEID}</Text>
                                                    <Flex flexDirection='row' gap={1} textAlign='center'>
                                                        <Text flex={1} fontSize='md' py={1} px={2} color='white' bgColor={(moment(appointment.HORACITA, 'h:m:s').isBefore(moment(), 'h:m:s') && appointment.ESTATUSCITA === 'S/N') ? 'red.500' : 'blue.500'} fontWeight='bold' borderRadius={2}><i class={(moment(appointment.HORACITA, 'h:m:s').isBefore(moment(), 'h:m:s') && appointment.ESTATUSCITA === 'S/N') ? 'bi bi-exclamation-circle' : 'bi bi-clock'}></i> {appointment.HORACITA}</Text>
                                                        <Text flex={1} fontSize='md' py={1} px={2} color='white' bgColor={status_colors[appointment.ESTATUSCITA]} fontWeight='bold' borderRadius={2}><i class={status_icon[appointment.ESTATUSCITA]}></i> {appointment.ESTATUSCITA}</Text>
                                                    </Flex>
                                                </Box>
                                            </GridItem>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Flex>
                    )
                    : (
                        <Box w='100%' h='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            <Spinner size='xl' thickness='6px' color='red.500' />
                            <Text>Cargando</Text>
                        </Box>
                    )
            }
        </>
    )
}

export default Citas